import React, { useState, useContext } from "react"
import Layout from "../components/layout/layout"
import { base, URLS } from "../config/urls"
import axios from "axios"
import { userContext } from "../context/userContext/userContext"
import { navigate } from "gatsby"
import SEO from "../components/seo/seo"
import { showError } from "../uitlity/Utility"
import FacebookLogin from "react-facebook-login"
import GoogleLogin from "react-google-login"

const LoginPage = props => {
  const ctx = useContext(userContext)
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()

  const [phoneNumber, setPhoneNumber] = useState("")
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [createPassword, setCreatePassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState()
  const [showCreateAccount, setShowCreateAccount] = useState(false)
  const [isMale, setIsMale] = useState(true)
  const [otpVerified, setOtpVerified] = useState(false)
  const [passwordVerified, setPasswordVerified] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [oneCapitalError, setOneCapitalError] = useState(false)
  const [oneLowerError, setOneLowerError] = useState(false)
  const [atLeastOneNumberError, setAtLeastOneNumberError] = useState(false)
  const [specialCharacterError, setSpecialCharacterError] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)

  const [page, setPage] = useState(1)

  function login() {
    axios({
      method: "post",
      url: URLS.loginUrl(),
      data: {
        username: username,
        password: password,
      },
    })
      .then(result => {
        ctx.setUser(result.data.data)
        ctx.setToken(result.data.auth.token)
        navigate("/dashboard")
      })
      .catch(error => {
        window.UIkit.modal.alert("Couldn't log in with given credentials")
        console.log(error)
      })
  }

  const sendOtp = () => {
    axios({
      method: "post",
      url: URLS.sendOTPUrl(),
      data: {
        mobile: phoneNumber,
      },
    })
      .then(result => {
        setShowCreateAccount(true)
        console.log(result)
      })
      .catch(error => console.log(error))
  }

  const verifyOtp = () => {
    axios({
      method: "post",
      url: URLS.verifyOTPUrl(),
      data: {
        otp: otp,
        mobile: phoneNumber,
      },
    })
      .then(result => {
        setOtpVerified(true)
      })
      .catch(error => {
        showError(error.response.data.data.msg)
      })
  }

  const register = () => {
    axios({
      method: "post",
      url: URLS.registerUrl(),
      data: {
        name: fullName,
        email: email,
        phone_number: phoneNumber,
        gender: isMale ? "MALE" : "FEMALE",
        username: email,
        first_name: fullName,
        password: createPassword,
        confirm_password: confirmPassword,
      },
    })
      .then(({ data }) => {
        ctx.setUser(data)
        navigate("/dashboard")
      })
      .catch(error => {
        showError(error.response.data[Object.keys(error.response.data)[0]][0])
        setIsMale(true)
        setPage(1)
        setOtpVerified(false)
        setConfirmPassword("")
        setCreatePassword("")
        console.log(error.response.data)
      })
  }

  return (
    <Layout>
      <SEO title="Login" />
      {page === 1 && (
        <>
          <div className="flex flex-column flex-row-ns">
            <div className="w-100 w-50-ns flex items-center justify-center pa6-ns pa3">
              <div className="w-100">
                <h1>Log In</h1>
                <div className="uk-margin">
                  <input
                    className="uk-input"
                    placeholder={"Email or Phone"}
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </div>
                <div className="uk-margin">
                  <input
                    className="uk-input"
                    placeholder={"password"}
                    value={password}
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div className="uk-margin">
                  <a href="/forgotPassword">forgot password</a>
                  <br />
                  <br />
                  <button
                    className="uk-button uk-button-primary"
                    onClick={login}
                  >
                    {" "}
                    Login
                  </button>
                </div>
                <p>or</p>
                <div className="uk-margin">
                  {/*<button className="uk-button">Login using Google</button>*/}
                  <GoogleLogin
                    clientId="787753798701-76gt4b6tfks08v2ohnn40fcgu25vucre.apps.googleusercontent.com"
                    buttonText="LOGIN WITH GOOGLE"
                    onSuccess={response => {
                      axios
                        .post(URLS.googleLogin(), {
                          auth_token: response.tokenId,
                        })
                        .then(result => {
                          ctx.setUser(result.data.data)
                          ctx.setToken(result.data.auth.token)
                          navigate("/dashboard")
                        })
                        .catch(error => {
                          window.UIkit.modal.alert(
                            "Couldn't log in with given credentials"
                          )
                          console.log(error)
                        })
                    }}
                    onFailure={response => {
                      // error popuyp here
                    }}
                  />
                </div>
                <div className="uk-margin">
                  {/*<button className="uk-button">Login using facebook</button>*/}
                </div>
                <FacebookLogin
                  textButton="LOGIN WITH FACEBOOK"
                  appId="141361034161034"
                  fields="name,email,picture"
                  callback={response => {
                    if (response.accessToken) {
                      axios
                        .post(URLS.facebookLogin(), {
                          auth_token: response.accessToken,
                        })
                        .then(result => {
                          ctx.setUser(result.data.data)
                          ctx.setToken(result.data.auth.token)
                          navigate("/dashboard")
                        })
                        .catch(error => {
                          window.UIkit.modal.alert(
                            "Couldn't log in with given credentials"
                          )
                          console.log(error)
                        })
                    }
                  }}
                />
              </div>
            </div>
            <hr
              class="uk-divider-vertical uk-visible@s"
              style={{ height: "70vh" }}
            />
            <hr className="uk-hidden@s" />
            <div className="w-100 w-50-ns flex items-start justify-center pa6-ns pa3">
              <div className="w-100">
                <h1>Register</h1>
                <div className="uk-margin">
                  <input
                    className={
                      "uk-input " + (nameError ? "uk-form-danger" : "")
                    }
                    placeholder={"Full Name"}
                    value={fullName}
                    onChange={e => {
                      setFullName(e.target.value)
                      setNameError(e.target.value === "")
                    }}
                  />
                  {nameError && (
                    <p style={{ color: "red" }}>Name is required</p>
                  )}
                </div>
                <div className="uk-margin">
                  <input
                    className={
                      "uk-input " + (emailError ? "uk-form-danger" : "")
                    }
                    placeholder={"Email Id"}
                    value={email}
                    onChange={e => {
                      let data = e.target.value
                      setEmailError(
                        !/^([a-z0-9_\-\.])+\@([a-z0-9_\-])+\.([a-z]{2,})$/.test(
                          data
                        )
                      )
                      setEmail(data)
                    }}
                  />
                  {emailError && <p style={{ color: "red" }}>Invaid email</p>}
                </div>
                <div className="uk-margin">
                  <input
                    className={
                      "uk-input " + (phoneError ? "uk-form-danger" : "")
                    }
                    placeholder={"Phone No."}
                    value={phoneNumber}
                    onChange={e => {
                      const data = e.target.value
                      setPhoneError(!/^\d{10}$/.test(data))
                      setPhoneNumber(data)
                    }}
                  />
                  {phoneError && (
                    <p style={{ color: "red" }}> Phone number is invalid</p>
                  )}
                </div>
                <label>
                  <input
                    className="uk-checkbox "
                    type="checkbox"
                    checked={isMale}
                    name="gender"
                    onChange={e => {
                      setIsMale(e.target.checked)
                    }}
                  />{" "}
                  isMale
                </label>
                <div className="uk-margin">
                  <button
                    className="uk-button uk-button-primary"
                    onClick={() => {
                      let error = false
                      if (fullName === "") {
                        setNameError(true)
                        error = true
                      }
                      if (
                        !/^([a-z0-9_\-\.])+\@([a-z0-9_\-])+\.([a-z]{2,})$/.test(
                          email
                        )
                      ) {
                        setEmailError(true)
                        error = true
                      }
                      if (!/^\d{10}$/.test(phoneNumber)) {
                        setPhoneError(true)
                        error = true
                      }
                      if (!error) {
                        sendOtp()
                        setPage(2)
                      }
                    }}
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {page === 2 && (
        <>
          <div className="flex items-center justify-center w-100 pa6-ns pa4">
            <div className="w-100" style={{ maxWidth: "400px" }}>
              <h2>Create Account</h2>
              <div className="uk-margin">
                <input
                  className="uk-input"
                  placeholder="OTP"
                  value={otp}
                  onChange={e => setOtp(e.target.value)}
                />
                <br />
                <br />
                <button className="uk-button" onClick={verifyOtp}>
                  OTP Verify
                </button>
              </div>
              <div className="uk-margin">
                <input
                  type="password"
                  className="uk-input"
                  disabled={!otpVerified}
                  placeholder="Create password"
                  value={createPassword}
                  onChange={e => {
                    let data = e.target.value
                    setOneCapitalError(/[A-Z]/.test(data))
                    setOneLowerError(/[a-z]/.test(data))
                    setAtLeastOneNumberError(/[0-9]/.test(data))
                    setSpecialCharacterError(/[,/<>@!]/.test(data))
                    setPasswordMatch(confirmPassword === data)
                    setCreatePassword(data)
                  }}
                />
              </div>
              <div className="uk-margin">
                <input
                  type="password"
                  className="uk-input"
                  disabled={!otpVerified}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={e => {
                    const data = e.target.value
                    setPasswordMatch(createPassword === data)
                    setConfirmPassword(data)
                  }}
                />
                <div className="uk-margin" style={{ color: "red" }}>
                  {!oneCapitalError && <div>one capital letter</div>}
                  {!oneLowerError && <div>one lower letter</div>}
                  {!atLeastOneNumberError && <div> at least one number</div>}
                  {!specialCharacterError && (
                    <div> at least one special character (,//&lt;&gt;@!)</div>
                  )}
                  {!passwordMatch && <div>password is not match</div>}
                </div>
              </div>
              <div className="uk-margin">
                <button
                  className="uk-button uk-button-primary"
                  disabled={!otpVerified}
                  onClick={() => {
                    register()
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  )
}

export default LoginPage
